









import { Component, Vue, Prop } from 'vue-property-decorator'
import PrescriptionStrength from '@/models/PrescriptionStrength'

@Component
export default class AddRxMedHeader extends Vue {
  @Prop({ type: [Object, String] })
  med!: PrescriptionStrength | string

  get name() {
    if (!this.med) return ''

    if (typeof this.med === 'string') {
      return this.med
    }

    return this.med.name
  }

  get strengthInfo() {
    if (!this.med) return null

    if (typeof this.med === 'string') {
      return null
    }

    return this.med.displayString
  }
}
